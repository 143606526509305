import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { PopoverController } from "@ionic/angular";
import { PopoverComponent } from "../popover/popover.component";
import { Subscription } from "rxjs";
import { SIZE_TO_MEDIA } from "@ionic/core/dist/collection/utils/media";
import { SplitPaneService } from "../../services/split-pane.service";
import { UsersService } from "../../services/users.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  isAuthenticated: boolean = false;
  toggleClass: string;
  @Input() title: string;
  @Input() showBackButton: boolean;
  autohide: boolean;
  splitpane: Subscription;
  currentUser = "";

  constructor(
    private authService: AuthService,
    private splitPaneService: SplitPaneService,
    public popoverController: PopoverController,
    private usersService: UsersService
  ) {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: "popover ion-margin-top",
      event: ev,
      translucent: true,
      showBackdrop: false,
    });
    popover.style.cssText = "--min-width: 150px; --max-width: 150px;";
    return await popover.present();
  }

  ngOnInit() {
    this.authService.class.subscribe((res) => {
      this.toggleClass = res;
    });
    this.authService.user.subscribe((user) => {
      this.isAuthenticated = !user ? false : true;
    });
    this.getCurrentUser();
    this.splitpane = this.splitPaneService.hideToggleButton.subscribe(
      (hide) => {
        this.autohide = hide;
      }
    );
  }

  getCurrentUser() {
    const email = JSON.parse(localStorage.getItem("email"));
    this.usersService.fetchUsers().subscribe((users) => {
      const user = users.find((user) => user.email == email);
      this.currentUser = `${user.firstName[0]}${user.lastName[0]}`;
    });
  }

  toggleMenu() {
    const splitPane = document.querySelector("ion-split-pane");
    if (
      window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches
    ) {
      splitPane.classList.toggle("split-pane-visible");
    }
    this.splitPaneService.nextHideToggleButton();
    // if(){}
  }

  onLogout() {
    this.authService.logout();
  }
}
