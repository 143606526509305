import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginPageModule } from "./pages/login/login.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptorService } from "./shared/services/auth-interceptor.service";
import { UsersPageModule } from "./pages/users/users.module";
import { UserDetailsPageModule } from "./pages/user-details/user-details.module";
import { SharedModule } from "./shared/modules/shared/shared.module";
import { MqttModule, IMqttServiceOptions } from "ngx-mqtt";
import { TestMqttService } from "./shared/services/mqtt.service";

const mqttUri = window["__env"]["mqttUri"] || "";
const token = JSON.parse(localStorage.getItem("token"));
const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: mqttUri,
  port: 1883,
  protocol: "wss",
  path: "",
  username: token ? token._access_token : "",
  password: "0",
};
import { EnvServiceProvider } from "./shared/services/env.service.provider";
import { PopoverComponent } from "./shared/components/popover/popover.component";

@NgModule({
    declarations: [AppComponent, PopoverComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        LoginPageModule,
        HttpClientModule,
        UsersPageModule,
        UserDetailsPageModule,
        SharedModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        EnvServiceProvider,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        TestMqttService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
