const base = window["__env"]["apiUri"] || "";
export const API_URLS = {
  /* Authentication */
  LOGIN: `${base}/auth/login`,
  /*users*/
  USERS: `${base}/user`,
  ADD_USER: `${base}/user`,
  DELETE_USER: (userId) => `${base}/user/${userId}`,
  UPDATE_USER: (userId) => `${base}/user/${userId}`,
  MESSAGES: (parameters) =>
    `${base}/message/?userEmail1=${parameters.userEmail1}&userEmail2=${parameters.userEmail2}&amount=${parameters.amount}`,
  DEVICES: `${base}/device`,
  ADD_DEVICE: `${base}/device`,
  DEVICE: (deviceId) => `${base}/device/${deviceId}`,
  DELETE_DEVICE: (deviceId) => `${base}/device/${deviceId}`,
  EVENTS: (parameters) =>
    `${base}/event/?fromDate=${parameters.fromDate}&toDate=${
      parameters.toDate ? parameters.toDate : ""
    }&eventType=${parameters.eventType}&subjectType=${
      parameters.subjectType
    }&senderUuid=${parameters.senderUuid}`,
};
