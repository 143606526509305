import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { User } from "src/app/shared/interfaces/user.interface";
import { UsersService } from "src/app/shared/services/users.service";
import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.page.html",
  styleUrls: ["./user-details.page.scss"],
})
export class UserDetailsPage implements OnInit {
  editMode: boolean = false;
  formValuesChanged: boolean = false;
  userDeleted: boolean = false;
  error: string = "";
  userToEdit: User = {
    firstName: "",
    lastName: "",
    email: "",
    department: "",
    position: "",
    password: "",
  };
  usersList: any[];
  userUpdated: boolean = false;
  userAdded: boolean = false;
  editable: boolean = false;
  editButtonText: string = "Edit";
  constructor(
    private userService: UsersService,
    public alertController: AlertController,
    private router: Router
  ) {}

  ngOnInit() {
    this.editMode = JSON.parse(localStorage.getItem("userEditMode"));
    setTimeout(() => {
      if (this.editMode) {
        this.userToEdit = JSON.parse(localStorage.getItem("userToEdit"));
      }
    }, 200);

    this.userService.fetchUsers().subscribe((res) => {
      this.usersList = res;
    });
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    if (!this.editMode) {
      const user = {
        firstName: form.value.firstName,
        lastName: form.value.lastName,
        email: form.value.email,
        department: form.value.department,
        position: form.value.position,
        password: form.value.password,
      };
      this.addUser(user);
      form.reset();
    } else {
      const payload = {};
      for (const key of Object.keys(form.controls)) {
        if (form.controls[key].value != this.userToEdit[key]) {
          if (form.controls[key].value.length > 0) {
            payload[key] = form.controls[key].value;
            this.userToEdit[key] = form.controls[key].value;
          }
        }
      }
      this.updateUser(payload);
    }
  }

  toggleEdit(form?: NgForm) {
    this.editable = !this.editable;
    if (this.editable) {
      this.editButtonText = "Cancel";
    } else {
      this.resetFormValues(form);
      this.editButtonText = "Edit";
      this.formValuesChanged = false;
    }
  }

  resetFormValues(form) {
    if (form) {
      for (const key of Object.keys(form.controls)) {
        if (this.userToEdit.hasOwnProperty(key)) {
          form.controls[key].setValue(this.userToEdit[key]);
        }
      }
      form.controls["password"].setValue("");
    }
  }

  updateUser(user: any) {
    this.userService.updateUser(this.userToEdit._id, user).subscribe(
      (response) => {
        this.formValuesChanged = false;
        this.userUpdated = true;
        setTimeout(() => {
          this.userUpdated = false;
        }, 3000);
        this.updateUsersList();
        this.editable = false;
        this.editButtonText = "Edit";
      },
      (errorMessage) => {
        this.error = errorMessage;
      }
    );
  }

  addUser(user: any) {
    this.userService.addUser(user).subscribe(
      (response) => {
        this.userAdded = true;
        setTimeout(() => {
          this.userAdded = false;
          this.router.navigate(["./home/users"]);
        }, 3000);
        this.updateUsersList();
      },
      (errorMessage) => {
        this.error = errorMessage;
      }
    );
  }
  updateUsersList() {
    this.userService.fetchUsers().subscribe((res) => {
      this.userService.usersList.next(res);
    });
  }

  inputChange(form: NgForm) {
    for (const key of Object.keys(form.controls)) {
      if (this.userToEdit.hasOwnProperty(key)) {
        if (form.controls[key].value == this.userToEdit[key]) {
          this.formValuesChanged = false;
        } else {
          this.formValuesChanged = true;
          break;
        }
      } else {
        if (form.controls[key].value != "") {
          this.formValuesChanged = true;
          break;
        } else {
          this.formValuesChanged = false;
        }
      }
    }
  }
  remove() {
    const userId = this.userToEdit._id;
    this.userService.deleteUser(userId).subscribe(
      (resData) => {
        setTimeout(() => {
          this.userDeleted = true;
        }, 100);
        setTimeout(() => {
          this.userDeleted = false;
          this.router.navigate(["./home/users"]);
        }, 3000);
        this.updateUsersList();
      },
      (error) => {}
    );
  }
  async presentAlertMultipleButtons() {
    const alert = await this.alertController.create({
      cssClass: "delete-alert",
      header: "Delete User?",
      message: "Are you sure you want to delete this User ?",
      buttons: [
        {
          text: "Cancel",
          cssClass: "primary",
          handler: (blah) => {},
        },
        {
          text: "Delete",
          cssClass: "primary",
          handler: () => {
            this.remove();
          },
        },
      ],
    });

    await alert.present();
  }
}
