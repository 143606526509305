import { Injectable } from "@angular/core";
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class TestMqttService {
  private endpoint: string;

  constructor(private _mqttService: MqttService) {}

  setEndpoint(deviceId: string) {
    this.endpoint = `event/device/${deviceId}/status/#`;
  }

  topic(): Observable<IMqttMessage> {
    let topicName = `${this.endpoint}`;
    return this._mqttService.observe(topicName);
  }

  requestRemoteControl(requestTopic: string, payload) {
    let topicName = requestTopic;
    this._mqttService.unsafePublish(topicName, payload);
  }

  remoteControlResponse(topic): Observable<IMqttMessage> {
    return this._mqttService.observe(topic);
  }
}
